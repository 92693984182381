<template>
    <li class="nav-item">
    <a 
      href="#" 
      class="nav-link" 
      @click.prevent="handleClick"
    >
        <span class="sidenav-mini-icon"> {{ miniIcon }} </span>
        <span class="sidenav-normal"> {{ text }} </span>
    </a>
    </li>
  </template>
  
  <script>
  export default {
    name: "SidenavJSItem",
    props: {
      miniIcon: {
        type: String,
        required: true,
      },
      text: {
        type: String,
        required: true,
      },
      onClick: {
        type: Function,
        required: true,
      },
    },
    //emits: ["click"], // Declare emitted events
    methods: {
      handleClick(event) {
      if (this.onClick && typeof this.onClick === 'function') {
        this.onClick(event);
      }
      //this.$emit('click', event);
    },
    },
  };
  </script>
  