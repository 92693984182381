<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Average Consulting Savings"
              :value="getAverageConsultingSavings"
              :description="formatChangeDescription(getSavingsChange)"
              :icon="{
                component: 'ni ni-chart-bar-32',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Average Gap Liability"
              :value="getAverageGap"
              :description="formatChangeDescription(getGapChange)"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Average Account Premium"
              :value="getAverageAccountPrem"
              :description="formatChangeDescription(getSavingsChange)"
              :icon="{
                component: 'ni ni-single-copy-04',
                background: 'bg-gradient-success',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Earnings Ratio"
              :value="getEarningsRatio"
              description="<span class='text-sm font-weight-bolder text-success'></span>
              <div class='fraction'>
                  <span class='numerator'>(New & Bound Account Premiums × 12.5%)</span>
                  <span class='denominator'>(All New Account Premiums × 12.5%)</span>
              </div>"
              :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-warning',
                  shape: 'rounded-circle'
              }"
            />
          </div>

        </div>
        <div class="row">
          <div class="col-lg-7 mb-lg under-construction-container">
            <table-switch />
          </div>
          <div class="col-lg-5">
            <carousel
              :items="[
                {
                  img: agencyLogo,
                  title: '',
                  description: '',
                  icon: {
                    component: '',
                    background: ''
                  }
                }
              ]"
            />
          </div>
        </div>
        <div class="row" style="margin-top: 20px; margin-bottom:-15px;">
          <div class="col-lg-3 col-md-6 col-12">
  <mini-statistics-card
    title="Closing Ratio"
    :value="getClosingRatio"
    :description="formatChangeDescription(getClosingChange)"
    :icon="{
      component: 'ni ni-chart-bar-32',
      background: 'bg-gradient-success',
      shape: 'rounded-circle'
    }"
  />
</div>
<div class="col-lg-3 col-md-6 col-12">
  <mini-statistics-card
    title="Presentation Goal (YTD)"
    :value="getPresentationsYtdProgress"
    :description="calculateProgressDifference(getPresentationsYtdProgressAsNum)"
    :icon="{
      component: 'ni ni-single-copy-04',
      background: 'bg-gradient-info',
      shape: 'rounded-circle'
    }"
  />
</div>
<div class="col-lg-3 col-md-6 col-12">
  <mini-statistics-card
    title="Income Goal (YTD)"
    :value="getIncomeYtdProgress"
    :description="calculateProgressDifference(getIncomeYtdProgressAsNum)"
    :icon="{
      component: 'ni ni-money-coins',
      background: 'bg-gradient-success',
      shape: 'rounded-circle'
    }"
  />
</div>

        </div>
        <div class="row mt-4">
          <div class="col-12">
            <proposals-table />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed bottom-1 end-1 z-index-2">
  <!-- Expand/Collapse Button -->
  <div class="d-flex justify-content-center mb-2 position-relative">
    <button @click="toggleNotifications" v-if="!animationInProgress || notificationsVisible" class="btn btn-primary" style="position: relative;">
      <i :class="notificationsVisible ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
      <!-- Message Count Badge, only shown if there are notifications -->
      <span v-if="notifications.length > 0" style="position: absolute; top: -8px; right: -8px; background-color: red; color: white; border-radius: 50%; padding: 2px 6px; font-size: 0.75rem; font-weight: bold;">
        {{ notifications.length }}
      </span>
    </button>
  </div>

  <!-- Sliding Notifications Container -->
  <transition name="slide-notifications" @after-enter="onAnimationEnd" @after-leave="onAnimationEnd">
    <div v-if="notificationsVisible" class="notifications-container">
      <div class="notifications-list">
        <argon-snackbar
          v-for="(notification, index) in notifications"
          :key="notification.notification_id"
          :title="notification.title"
          :date="notification.date"
          :description="notification.description"
          :icon="notification.icon"
          :color="notification.color"
          :proposal_id="notification.proposal_id"
          :notification_id="notification.notification_id"
          :close-handler="() => removeNotification(index)"
        />
      </div>
    </div>
  </transition>
</div>


</template>

<script>
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import ProposalsTable from "./components/ProposalsTable.vue";
import Carousel from "./components/Carousel.vue";
import TableSwitch from "./components/TableSwitch.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue"; // Ensure this path is correct

import image2 from "../../assets/img/img-2.jpg";
import image1 from "../../assets/img/img-1.jpg";
import image3 from "../../assets/img/img-3.jpg";

import team1 from "../../assets/img/team-1.jpg";
import team2 from "../../assets/img/team-2.jpg";
import team5 from "../../assets/img/team-5.jpg";
import team4 from "../../assets/img/team-4.jpg";

import jira from "../../assets/img/small-logos/logo-jira.svg";
import asana from "../../assets/img/small-logos/logo-asana.svg";
import spotify from "../../assets/img/small-logos/logo-spotify.svg";
import bootstrap from "../../assets/img/small-logos/bootstrap.svg";
import invision from "../../assets/img/small-logos/logo-invision.svg";
import slack from "../../assets/img/small-logos/logo-slack.svg";
import adobe from "../../assets/img/small-logos/logo-xd.svg";

import US from "../../assets/img/icons/flags/US.png";
import DE from "../../assets/img/icons/flags/DE.png";
import GB from "../../assets/img/icons/flags/GB.png";
import BR from "../../assets/img/icons/flags/BR.png";
import axios from 'axios';

export default {
  name: "DashboardDefault",
  components: {
    MiniStatisticsCard,
    ProposalsTable,
    Carousel,
    TableSwitch,
    ArgonSnackbar
  },
  data() {
    return {
      image2,
      image1,
      image3,
      team1,
      team2,
      team5,
      team4,
      jira,
      asana,
      spotify,
      bootstrap,
      invision,
      slack,
      adobe,
      sales: {
        us: {
          country: "United States",
          sale: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US
        },
        germany: {
          country: "Germany",
          sale: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE
        },
        britain: {
          country: "Great Britain",
          sale: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB
        },
        brasil: {
          country: "Brasil",
          sale: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR
        }
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Real Earnings',
            data: [],
            borderColor: '#4BB543',
            backgroundColor: 'rgba(75, 181, 67, 0.2)',
            borderWidth: 1
          },
          {
            label: 'Earnings Ratio',
            data: [],
            borderColor: '#3A416F',
            backgroundColor: 'rgba(58, 65, 111, 0.2)',
            borderWidth: 1
          }
        ]
      },
      averageConsultingSavings: 0,
      averageGapLiability: 0,
      averageAccountPremium: 0,
      earningsRatio: 0,
      closingRatio: 0,
      notifications: [], // Add notifications array to data
      animationInProgress: false, // Track if the animation is in progress
    };
  },
  computed: {

    notificationsVisible() {
      return this.$store.getters['notifications/notificationsVisible'];
    },
    
    getIncomeYtdProgress() {
      let value = this.$store.getters['profile/me']?.incomeYtdProgress || 0;
      
      // Handle "N/A" or other non-numeric values
      if (isNaN(value) || value === "N/A") {
        return "N/A";  // Return "N/A" or handle it appropriately
      }
      
      return this.formatPercent(value, true);
    },
    
    getPresentationsYtdProgress() {
      let value = this.$store.getters['profile/me']?.presentationsYtdProgress || 0;
      
      // Handle "N/A" or other non-numeric values
      if (isNaN(value) || value === "N/A") {
        return "N/A";  // Return "N/A" or handle it appropriately
      }
      
      return this.formatPercent(value, true);
    },
    
    getIncomeYtdProgressAsNum() {
      let value = this.$store.getters['profile/me']?.incomeYtdProgress || 0;
      
      // Handle "N/A" or other non-numeric values
      if (isNaN(value) || value === "N/A") {
        return 0;  // Return 0 or another default value for numeric context
      }
      
      return parseFloat(value);  // Ensure value is returned as a number
    },
    
    getPresentationsYtdProgressAsNum() {
      let value = this.$store.getters['profile/me']?.presentationsYtdProgress || 0;
      
      // Handle "N/A" or other non-numeric values
      if (isNaN(value) || value === "N/A") {
        return 0;  // Return 0 or another default value for numeric context
      }
      
      return parseFloat(value);  // Ensure value is returned as a number
    },
    getClosingRatio() {
      let value = this.$store.getters['profile/me']?.closing_ratio || 0;
      return this.formatPercent(value);
    },
    getEarningsRatio() {
      let value = this.$store.getters['profile/me']?.earnings_ratio || 0;
      return this.formatPercent(value);
    },
    getAverageConsultingSavings() {
      let value = this.$store.getters['profile/me']?.average_savings || 0;
      return this.formatCurrency(value);
    },
    getAverageGap() {
      let value = this.$store.getters['profile/me']?.average_gap || 0;
      return this.formatCurrency(value);
    },
    getAverageAccountPrem() {
      let value = this.$store.getters['profile/me']?.average_annual_prem || 0;
      return this.formatCurrency(value);
    },
    agencyLogo() {
      if (this.$store.getters['profile/me']?.role === 'global_admin') {
        return require('@/assets/img/logos/admin.png');
      } else {
        return this.$store.getters['profile/me']?.agency_logo;
      }
    },

  // New computed properties to calculate and return the percent change values
  getSavingsChange() {
    let value = this.$store.getters['profile/me']?.savings_change || 0;
    return this.formatPercent(value, true);
  },
  
  getGapChange() {
    let value = this.$store.getters['profile/me']?.gap_change || 0;
    return this.formatPercent(value, true);
  },
  
  getPremiumChange() {
    let value = this.$store.getters['profile/me']?.premium_change || 0;
    return this.formatPercent(value, true);
  },
  
  getEarningsChange() {
    let value = this.$store.getters['profile/me']?.earnings_change || 0;
    return this.formatPercent(value, true);
  },
  
  getClosingChange() {
    let value = this.$store.getters['profile/me']?.closing_change || 0;
    return this.formatPercent(value, true);
  },
  },
  created() {
    this.$store.dispatch('notifications/initializeNotificationsVisibility');
    this.ensureDataReady();
    this.fetchChartData();
    this.fetchNotifications(); // Fetch notifications on component creation
  },
  methods: {
    formatChangeDescription(change) {
    // Convert change to a number for accurate comparison
    const changeNum = parseFloat(change);

    // Determine the class based on the numeric change value
    let changeClass = changeNum >= 20 ? 'text-success' : 'text-danger';
    if (changeNum > 2) {
      changeClass = 'text-success';
    } else {
      changeClass = 'text-danger';
    }

    // Determine the sign to display
    const sign = changeNum > 0 ? '+' : '';

    // Return the formatted string as a string but use the numeric value for comparison
    return `<span class='text-sm font-weight-bolder ${changeClass}'>${sign}${changeNum.toFixed(1)}%</span> change from last month`;
  },
    calculateProgressDifference(percentage) {
    const difference = 100 - percentage; // Calculate how much is missing to reach 100%

    console.log(`Percentage: ${percentage}`);
    console.log(`Difference: ${difference}`);

    if (difference > 0) {
      console.log(`Returning: ${difference.toFixed(1)}% behind`);
      return `<span class='text-sm font-weight-bolder text-danger'>${difference.toFixed(1)}%</span> behind where you should be`;
    } else if (difference < 0) {
      console.log(`Returning: ${Math.abs(difference).toFixed(1)}% better`);
      return `<span class='text-sm font-weight-bolder text-success'>+${Math.abs(difference).toFixed(1)}%</span> better than planned`;
    } else {
      console.log('Returning: on track');
      return `<span class='text-sm font-weight-bolder text-success'>on track</span>`;
    }
  },
    async fetchChartData() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/chart-data`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const data = response.data;
          console.log(data); // Debugging: Check the data structure
          this.chartData.labels = data.labels;
          this.chartData.datasets[0].data = data.datasets[0].data;
          this.chartData.datasets[1].data = data.datasets[1].data;
          this.averageConsultingSavings = data.averageConsultingSavings;
          this.averageGapLiability = data.averageGapLiability;
          this.averageAccountPremium = data.averageAccountPremium;
          this.earningsRatio = data.earningsRatio;
          this.closingRatio = data.closingRatio;
        } catch (error) {
          console.error('Error fetching chart data', error);
        }
      } else {
        console.error('No access token found in localStorage');
      }
    },
    async ensureDataReady() {
      if (!this.getAverageConsultingSavings) {
        await this.$store.dispatch('profile/me');
      }
      // Similarly ensure other data needed beforehand is fetched
    },
    formatPercent(value, alreadyPercent = false) {
      if (typeof value !== 'number') {
        throw new Error('Value must be a number');
      }

      let percentValue =  value;

      if (!alreadyPercent) {
        percentValue = value * 100;
      }
      return percentValue.toFixed(1) + '%';
    },
    formatCurrency(value) {
      // Round the value to the nearest whole number
      value = Math.round(value);

      // Format the rounded value as currency
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Ensure no decimal places are shown
        maximumFractionDigits: 0  // Ensure no decimal places are shown
      }).format(value);
    },
    async fetchNotifications() {
      const token = localStorage.getItem('access_token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/notifications`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          this.processNotifications(response.data);
          console.log("NOTIFICATIONS: : :", this.notifications); // Debugging: Check the notification data structure
        } catch (error) {
          if (error.response) {
            // The request was made, and the server responded with a status code
            // that falls outside the range of 2xx
            console.error(`Error fetching notifications: ${error.message}`);
            console.error(`Status code: ${error.response.status}`);
            console.error('Response data:', error.response.data);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('Error fetching notifications: No response received from the server');
            console.error('Request data:', error.request);
          } else {
            // Something else happened in setting up the request
            console.error('Error:', error.message);
          }
        }

      } else {
        console.error('No access token found in localStorage');
      }
    },
    processNotifications(apiNotifications) {
      // Parse API notifications into the desired format
      this.notifications = apiNotifications.map(notification => {
        // Determine the color and icon based on the notification type or category
        let color, icon;

        switch (notification.type) {
          case 'success':
            color = 'success';
            icon = { component: "ni ni-check-bold", color: "white" };
            break;
          case 'info':
            color = 'info';
            icon = { component: "ni ni-bell-55", color: "info" };
            break;
          case 'warning':
            color = 'warning';
            icon = { component: "ni ni-spaceship", color: "white" };
            break;
          case 'danger':
            color = 'danger';
            icon = { component: "ni ni-notification-70", color: "danger" };
            break;
          default:
            color = 'info';
            icon = { component: "ni ni-bell-55", color: "info" };
            break;
        }

        // Return the transformed notification object
        return {
          title: notification.title || "Notification",
          date: notification.created_at ? this.formatDate(notification.created_at) : "Just now",
          description: notification.message || "No description available.",
          icon: icon,
          color: color,
          proposal_id: notification.proposal_id,
          notification_id: notification.id
        };
      });
    },

    // Helper method to format date (You can customize this)
    formatDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();
      const diffInMinutes = Math.floor((now - date) / (1000 * 60));

      if (diffInMinutes < 1) {
        return "Just now";
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} mins ago`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} hours ago`;
      }
    },
    fetchNotificationsOld() {
      // Mimic an API call to fetch notifications
      this.notifications = [
        {
          title: "Success",
          date: "5 mins ago",
          description: "This is a success notification.",
          icon: { component: "ni ni-check-bold", color: "white" },
          color: "success"
        },
        {
          title: "Info",
          date: "10 mins ago",
          description: "This is an info notification.",
          icon: { component: "ni ni-bell-55", color: "info" },
          color: "info"
        },
        {
          title: "Warning",
          date: "15 mins ago",
          description: "This is a warning notification.",
          icon: { component: "ni ni-spaceship", color: "white" },
          color: "warning"
        },
        {
          title: "Danger",
          date: "20 mins ago",
          description: "This is a danger notification.",
          icon: { component: "ni ni-notification-70", color: "danger" },
          color: "danger"
        },
        {
          title: "Additional Success",
          date: "25 mins ago",
          description: "This is another success notification.",
          icon: { component: "ni ni-check-bold", color: "white" },
          color: "success"
        },
        {
          title: "Additional Info",
          date: "30 mins ago",
          description: "This is another info notification.",
          icon: { component: "ni ni-bell-55", color: "info" },
          color: "info"
        },
        {
          title: "Additional Warning",
          date: "35 mins ago",
          description: "This is another warning notification.",
          icon: { component: "ni ni-spaceship", color: "white" },
          color: "warning"
        },
        {
          title: "Additional Danger",
          date: "40 mins ago",
          description: "This is another danger notification.",
          icon: { component: "ni ni-notification-70", color: "danger" },
          color: "danger"
        }
      ];
    },
    async removeNotification(index) {
      const notificationToDelete = this.notifications[index];
      const notificationId = notificationToDelete.notification_id;

      console.log("Deleting notification with id:", notificationId);
      console.log("INDEX in notifications:", index);

      try {
        const token = localStorage.getItem('access_token'); // Get the token from localStorage

        // Make the PUT request to the dismissal endpoint
        const response = await axios.put(
          `${process.env.VUE_APP_API_BASE_URL}/notifications/${notificationId}/dismiss`,
          {}, // Request body (if needed, adjust accordingly)
          {
            headers: {
              'Authorization': `Bearer ${token}` // Include the authorization header
            }
          }
        );

        // Check if the response status is not 200
        if (response.status !== 200) {
          throw new Error(`Unexpected response status: ${response.status}`);
        }

        console.log("Notification dismissed:", response.data);

        // Remove the notification from the list
        this.notifications.splice(index, 1);

        // Ensure the DOM updates
        this.$nextTick(() => {
          console.log("DOM updated after notification removal");
        });
      } catch (error) {
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          console.error(`Error dismissing notification: ${error.message}`);
          console.error(`Status code: ${error.response.status}`);
          console.error("Response data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error dismissing notification: No response from server");
          console.error("Request data:", error.request);
        } else {
          // Other errors, like setting up the request
          console.error("Error dismissing notification:", error.message);
        }
      }
    },

    toggleNotifications() {
      this.animationInProgress = true;
      this.$store.dispatch('notifications/toggleNotificationsVisibility');
    },
    onAnimationEnd() {
      this.animationInProgress = false;
    }
  }
};
</script>

<style scoped>
.notification-bubble {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.5em;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8em;
}
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  position: relative;
}

.carousel-item::before {
  content: "";
  display: block;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.under-construction-container {
  position: relative;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  font-size: 5rem;
  pointer-events: none;
  z-index: 2;
  text-align: center;
  width: 100%;
}

.notifications-container {
  width: 320px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.notifications-list {
  padding: 5px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Sliding animation for notifications container */
.slide-notifications-enter-active, .slide-notifications-leave-active {
  transition: all 0.4s ease;
}

.slide-notifications-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-notifications-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-notifications-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.slide-notifications-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

/* Button rotation */
button i {
  transition: transform 0.4s ease;
}

.notifications-container + button i {
  transform: rotate(180deg);
}

/* Notifications container styling */
.notifications-container {
  width: 320px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.notifications-list {
  padding: 5px;
}

/* Button styles */
.position-fixed {
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
}

</style>

<style>
.fraction span {
  font-size: 12px;
}
.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.numerator {
  display: block;
  border-bottom: 1px solid;
  padding: 0 5px;
}
.denominator {
  display: block;
  padding: 0 5px;
}
</style>
