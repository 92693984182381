<template>
  <div class="wasm-app-wrapper">
    <div class="wasm-app-container">
      <iframe id="qtAppIframe" :src="iframeSrc" class="wasm-iframe"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: null, // Optional; only required for resuming
    },
  },
  computed: {
    iframeSrc() {
      const baseUrl = "/wasm/Protego-WebApp.html"; // Path to your WASM app's HTML entry point
      const params = new URLSearchParams({
        id: this.id, // Add ID only if resuming
      });
      return `${baseUrl}?${params.toString()}`; // Full URL with query params
    },
  },
  created() {
    // Get the API base URL from the environment variable
    const fullApiUrl = process.env.VUE_APP_API_BASE_URL || '';
    
    // Remove "vue" from the end, preserving the trailing slash
    const apiUrlWithoutVue = fullApiUrl.replace(/\/?vue$/, '/');

    // Store the modified API URL in localStorage
    localStorage.setItem('apiBaseUrl', apiUrlWithoutVue);

    // Log for debugging purposes (remove in production)
    console.log('API Base URL stored in localStorage:', apiUrlWithoutVue);
  },
};
</script>

<style>
/* Wrapper for the WASM app */
.wasm-app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -7%;
  
  width: 100%; /* Full width */
  height: 100vh; /* Full viewport height */
  background-color: transparent; /* Light background */
  border-radius: 8px; /* Rounded corners for the container */

  overflow: hidden; /* Prevent overflow outside the wrapper */
}

/* Container for scrolling */
.wasm-app-container {
  width: 85%; /* Container width */
  max-width: 1100px; /* Limit max width for larger screens */
  height: 90%; /* Adjust height */
  max-height: 800px; /* Limit height for larger screens */
  background-color: white; /* Background for the container */
  border: 3px solid #bbb; /* Add border for visibility */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  touch-action: pan-x pan-y; /* Enable touch gestures for scrolling */
}

/* Styling for the iframe */
.wasm-iframe {
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  border: none; /* Remove iframe border */
  border-radius: 8px; /* Rounded corners for the container */

  background-color: transparent; /* Transparent background for the iframe */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .wasm-app-container {
    width: 100%; /* Take full width on smaller screens */
    height: 100%; /* Take full height on smaller screens */
  }
}

/* Tablet-specific adjustments */
@media (min-width: 768px) and (max-width: 1024px) {
  .wasm-app-container {
    width: 80%; /* Adjust width for tablets */
    height: 80%; /* Adjust height for tablets */
  }
}
</style>
