<template>
  <transition name="fade-slide">
    <div
      v-if="visible"
      class="toast fade show p-2 mt-2"
      :class="getColor(color)"
    >
      <div class="toast-header bg-transparent border-0">
        <i class="me-2" :class="getIcon(icon)" />
        <span
          ref="titleElement"
          class="me-auto font-weight-bold cursor-pointer"
          :class="getTextColor(color)"
        >
          {{ title }}
        </span>
        <small :class="getTextColor(color)">{{ date }}</small>
        <i
          class="fas fa-times text-md ms-3 cursor-pointer"
          :class="getTextColor(color)"
          @click="handleClose"
        />
      </div>
      <hr class="horizontal dark m-0" :class="getHrColor(color)" />
      <div class="toast-body" :class="getTextColor(color)">
        {{ description }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ArgonSnackbar",
  props: {
    notification_id: {
      type: Number,
      required: true,
    },
    proposal_id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: [String, Object],
      default: () => ({
        color: "success",
      }),
    },
    color: {
      type: String,
      default: "success",
    },
    closeHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      visible: true, // Control the visibility of the notification
    };
  },
  methods: {
    getColor(color) {
      return color === "white" ? "bg-white" : `bg-gradient-${color}`;
    },
    getIcon(icon) {
      if (icon && typeof icon === "string") {
        return icon;
      } else if (icon && typeof icon === "object") {
        return `${icon.component} text-${icon.color}`;
      } else {
        return null;
      }
    },
    getTextColor(color) {
      return color === "white" ? "text-dark" : "text-white";
    },
    getHrColor(color) {
      return color === "white" ? "dark" : "light";
    },
    navigateToRoute() {
      console.log("Navigating to /manage/" + this.proposal_id);
      this.$router.push({ path: `/manage/${this.proposal_id}` });
    },
    handleClose() {
      this.visible = false; // Trigger the fade-out animation
      setTimeout(() => {
        if (this.closeHandler) {
          this.closeHandler(); // Call the external close handler after the animation
        }
      }, 300); // Match the duration to the CSS transition time
    },
  },
  mounted() {
    const titleElement = this.$refs.titleElement;
    if (titleElement) {
      titleElement.addEventListener("click", this.navigateToRoute);
    }
  },
  beforeUnmount() {
    const titleElement = this.$refs.titleElement;
    if (titleElement) {
      titleElement.removeEventListener("click", this.navigateToRoute);
    }
  },
};
</script>

<style scoped>
.toast {
  transition: opacity 0.3s;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px); /* Slide effect */
}
</style>
