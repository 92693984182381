<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-8 col-12">
        <div class="row">
          <div class="col-lg-4 col-12">
            <!-- Statistics Card with Manage Button -->
            <statistics-card title="Estimated Earnings" :count="`$${formatNumber(estimatedEarnings)}`"
              :badge="{ color: '', label: '' }" :action="{ label: 'Manage' }" :onClick="openModal" />
          </div>
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card title="Annual Premium" :value="`$${formatNumber(annualPremium)}`" :icon="{
              component: 'ni ni-money-coins',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
            <mini-statistics-card title="Gap Liability" :value="`$${formatNumber(gapLiability)}`" :icon="{
              component: 'ni ni-planet',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="mt-4 col-lg-4 col-md-6 col-12 mt-lg-0">
            <mini-statistics-card title="Consulting Savings" :value="`$${formatNumber(consultingSavings)}`" :icon="{
              component: 'ni ni-world',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
            <mini-statistics-card title="Probability to close" :value="formatPercent(probabilityToClose)" :icon="{
              component: 'ni ni-shop',
              background: 'bg-gradient-dark',
              shape: 'rounded-circle',
            }" />
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-4 col-12 mt-lg-0">
        <announcement-card
          description="9-Triggers to Bind-it is our flagship campaign automation tool. It is designed to automate your campaigns and client correspondence."
          :by="{
            image: image,
            name: 'Account Management System',
            date: '2h ago',
          }" :badge="{ color: 'info', label: 'Book Automation' }" :value="{
            label: 'Tasks',
            count: '5',
            unit: 'remaining',
          }" :action="{
            label: '9-Triggers to Bind-it'
          }" :summary="{
            campaignType: 'Standard Campaign',
            progress: 0,
            expirationDate: ''
          }" 
            @click="handleCardClick"
            />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-8 col-12">
        <todo-list :header="{ title: 'Service Strategy' }" />
      </div>
      <div class="mt-4 col-lg-4 col-12 mt-lg-0">
        <progress-line-chart title="Tasks" :count="480" :progress="60" :chart="{
          labels: [
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          data: [40, 45, 42, 41, 40, 43, 40, 42, 39],
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCard from "./components/StatisticsCard.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import AnnouncementCard from "./components/AnnouncementCard.vue";
import ProgressLineChart from "@/examples/Charts/ProgressLineChart.vue";
import TodoList from "./components/TodoList.vue";
import image from "@/assets/img/team-3.jpg";
import eventBus from "@/services/event-bus.service.js";
//import { nextTick } from 'vue';
import axios from "axios";


export default {
  name: "GeneralSearch",
  components: {
    MiniStatisticsCard,
    AnnouncementCard,
    ProgressLineChart,
    TodoList,
    StatisticsCard,
  },
  data() {
    return {
      contactEmail: '',
      numberOfEmployees: 0,
      xDate: '',
      image,
      estimatedEarnings: 0,
      annualPremium: 0,
      gapLiability: 0,
      consultingSavings: 0,
      probabilityToClose: 0,
    };
  },
  async mounted() {
    // Ensure listener registration before emitting
    await this.registerAndEmitSearch();
  },
  beforeUnmount() {
    // Hide search bar on unmount
    eventBus.emit("toggleHeaderSearch", false);
  },
  methods: {
    handleCardClick() {
      this.showSwal('warning-auto-close');

    //if (!this.accountId) {
    //  this.showSwal('warning-auto-close');
    //} else {
      // Navigate to the route or handle the normal action
      //this.$router.push(`/account/${this.accountId}`);
    //}
  },

  showSwal(type) {
    if (type === 'warning-auto-close') {
      let timerInterval;
      this.$swal({
        icon: 'warning',
        title: 'Account is not selected.',
        text: 'Please search an account first.',
        timer: 1200, // 2 seconds
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading();
          const b = this.$swal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            if (b) b.textContent = this.$swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        customClass: {
          confirmButton: 'btn bg-gradient-success',
        },
        buttonsStyling: false,
      });
    }
  },
    async registerAndEmitSearch() {
      const accessToken = localStorage.getItem("access_token");
            
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/get-accounts`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log("RESPONSE: ", response.data);

      // Wait until DOM and listeners are ready
      //await nextTick();

      setTimeout(() => {
        eventBus.emit("toggleHeaderSearch", true);
        eventBus.emit("updateProposalsList", response.data.sessions);

      }, 50); // Delay ensures listeners are registered
    },
    formatNumber(number) {
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 });
    },
    formatPercent(number) {
      return (number / 100).toLocaleString('en-US', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
