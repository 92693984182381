<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Members List</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Invite' }" class="mb-0 btn bg-gradient-success btn-sm">
                    <i class="fa fa-plus me-1"></i> Invite
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="px-0 pb-0 card-body">
            <div class="dataTable-search search-block">
              <input v-model="search" class="dataTable-input search-input-table" placeholder="Search..." type="text">
            </div>
            <div class="table-responsive">
              <table id="user-list" ref="userList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Invite Accepted</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-for="item in filteredList" :key="item.id">
                    <td>{{ item.name || 'N/A' }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.role === 'agency_admin' ? 'Agency Admin' : item.role === 'agency_agent' ? 'Producer' : item.role }}</td>
                    <td>{{ item.is_invite ? 'No' : 'Yes' }}</td>
                    <td>
                      <button v-if="item.is_invite" :onclick="'deleteInvite(' + item.id + '); return false;'" class="btn btn-danger btn-sm">Delete Invite</button>
<button v-if="!item.is_invite" :onclick="'goToBusinessPlan(' + item.id + '); return false;'" class="btn btn-success btn-sm padding-right">Producer Plan</button>
<!--button v-if="!item.is_invite" :onclick="'deleteInvite(' + item.id + '); return false;'" class="btn btn-danger btn-sm">Delete User</button-->

                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Invite Accepted</th>
                    <th>Actions</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("access_token")}`
});

export default {
  name: "ManageMembers",
  data() {
    return {
      dataItems: [],
      pagination: {
        currentPage: 1,
        perPage: 5,
        total: 0
      },
      tableUsers: null,
      search: '',
      errorMessage: '',
      initialized: false
    };
  },
  computed: {
    filteredList() {
      return this.dataItems.filter(item =>
        (item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.email && item.email.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    metaPage() {
      return this.pagination;
    }
  },
  watch: {
    search() {
      this.refreshTable();
    }
  },
  async mounted() {
    await this.fetchData();
    this.initializeDataTable();

    window.goToBusinessPlan = this.goToBusinessPlan.bind(this);
    window.deleteInvite = this.deleteInvite.bind(this);
  },
  methods: {
    async fetchData() {
      try {
        console.log("Fetching data");
        const response = await axios.get(`${API_URL}/agency/invites-users`, { headers: authHeader() });
        this.dataItems = response.data.data;
        this.pagination.total = this.dataItems.length;
        this.refreshTable();
      } catch (error) {
        console.error("Error fetching data:", error);
        this.errorMessage = "Failed to load data";
      }
    },
    initializeDataTable() {
      if (this.$refs.userList && !this.initialized) {
        this.tableUsers = new DataTable(this.$refs.userList, {
          searchable: false,
          fixedHeight: false,
          perPage: this.pagination.perPage
        });
        this.initialized = true;
      }
    },
    refreshTable() {
      if (this.tableUsers) {
        this.tableUsers.destroy();
        this.initializeDataTable();
        this.tableUsers.insert({
          data: this.filteredList.map(item => [
            item.name || "N/A",
            item.email,
            item.role,
            item.is_invite ? "No" : "Yes",
            item.is_invite 
  ? `<button class="btn btn-danger btn-sm" onclick="deleteInvite(${item.id}); return false;">Delete Invite</button>` 
  : `<button class="btn btn-success btn-sm padding-right" onclick="goToBusinessPlan(${item.id}); return false;">Producer Plan</button>`
          ])
        });
      }
    },
    goToBusinessPlan(userId) {
      this.$router.push({ name: "Business Plan", params: { user_id: userId } });
    },
    async deleteInvite(inviteId) {
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "Do you want to delete this invite? This action cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          // Proceed with deletion
          await axios.delete(`${API_URL}/invites/${inviteId}`, { headers: authHeader() });
          await this.fetchData();
          window.location.reload();
          this.$swal({
            title: "Deleted!",
            text: "The invite has been deleted successfully.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal({
            title: "Cancelled",
            text: "Your invite is safe.",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
        }
      } catch (error) {
        console.error("Error deleting invite:", error);
        this.$swal({
          title: "Error",
          text: "An error occurred while deleting the invite. Please try again.",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      }
    },
    async getDataFromPage(page) {
      this.pagination.currentPage = page;
      await this.fetchData();
    }
  }
};
</script>

<style scoped>
.padding-right {
  margin-right: 10px !important;
}
</style>
