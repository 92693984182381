<template>
  <div class="card">
    <div class="table-responsive">
      <table :key="tableKey" id="datatable-search" class="table table-fixed">
        <thead>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex align-items-center flex-grow-1">
              <button @click="openNewProposal" class="mb-0 btn bg-gradient-primary btn-sm" style="color: white; margin-left: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding:10px; font-size: 12px;">
                <i class="fa fa-plus me-1"></i> New Proposal
              </button>
              <button @click="exportAll" class="mb-0 btn bg-gradient-primary btn-sm" style="color: white; margin-left: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding:10px; font-size: 12px;">
                <i class="fa fa-download me-1"></i> Export All (CSV)
              </button>
              <button @click="openImportWizard" class="mb-0 btn bg-gradient-primary btn-sm" style="color: white; margin-left: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding:10px; font-size: 12px;">
                <i class="fa fa-upload me-1"></i> Import (CSV)
              </button>
              <ImportWizard v-if="showImportWizard" @closeModal="handleCloseWizard" />
            </div>
            <slot name="entries-per-page-selector"></slot>
          </div>
          <tr>
            <th v-if="isAdmin" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Agency Name</th>
            <th v-if="isAdmin || isAgencyAdmin" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Producer Name</th>
            <th v-if="!isAdmin" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Status</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Account Name</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1" style="width: 250px;">X-Date</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">New</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Bound</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Lost</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">Premium</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="proposal in filteredProposals" :key="proposal.id">
            <th v-if="isAdmin" class="align-middle text-center p-1">{{ proposal.agency_name ? proposal.agency_name : 'N/A' }}</th>
            <td v-if="isAdmin || isAgencyAdmin" class="align-middle text-center p-1">{{ proposal.last_edited_by ? proposal.last_edited_by : 'N/A' }}</td>
            <td v-if="!isAdmin" class="align-middle text-center p-1">
              <a class="text-primary" v-if="!proposal.is_finished" href="#" :onclick="'continueProposal(' + proposal.id + '); return false;'">
                Continue
              </a>
              <span v-else>
                Completed
              </span>
            </td>
            <td class="align-middle text-center p-1">
              <router-link :to="{ name: 'General', params: { accountId: proposal.id } }" :title="proposal.client_name">
                {{ truncateText(proposal.client_name, 20) }}
              </router-link>
            </td>
            <td class="align-middle text-center p-1" style="width: 250px;">{{ formatDate(proposal.x_date) }}</td>
            <td class="align-middle text-center p-1">
              <input style="height:20px; width:20px;" type="checkbox" :checked="proposal.is_new" :onclick="'handleNewClick(' + proposal.id + ')'" />
              <span class="d-none">{{ proposal.is_new ? 'New' : '' }}</span>
            </td>
            <td class="align-middle text-center p-1">
              <label class="checkbox-custom checkbox-bound" style="position: relative; display: inline-block; margin-top: 11px; width: 20px; height: 20px; cursor: pointer; border: 1px solid #ccc;">
                <input type="checkbox" :id="'boundCheckbox-' + proposal.id" :checked="proposal.is_bound" :onclick="'handleBoundClick(' + proposal.id + ')'" style="opacity: 0; position: absolute; width: 100%; height: 100%;" />
                <span style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">{{ '' }}</span>
              </label>
              <span class="d-none">{{ proposal.is_bound ? 'Bound' : '' }}</span>
            </td>
            <td class="align-middle text-center p-1">
              <label class="checkbox-custom checkbox-lost" style="position: relative; display: inline-block; margin-top: 11px; width: 20px; height: 20px; cursor: pointer; border: 1px solid #ccc;">
                <input type="checkbox" :id="'lostCheckbox-' + proposal.id" :checked="proposal.is_lost" :onclick="'handleLostClick(' + proposal.id + ')'" style="opacity: 0; position: absolute; width: 100%; height: 100%;" />
                <span style="position: absolute; top: 0; left: 0; right: 0; bottom: 0;">{{ '' }}</span>
              </label>
              <span class="d-none">{{ proposal.is_lost ? 'Lost' : '' }}</span>
            </td>
            <td class="align-middle text-center p-1">${{ formatCurrency(proposal.approx_annual_premium) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { mapActions, mapGetters } from 'vuex';
import { DataTable } from "simple-datatables";
import axios from 'axios';
import eventBus from "@/services/event-bus.service.js"
import ImportWizard from './ImportWizard.vue';

export default {
  name: "ProposalsTable",
  components: {
    ImportWizard,
  },
  data() {
    return {
      tableKey: 0,
      loading: false,
      dataTable: null,
      showImportWizard: false,
    };
  },
  computed: {
    ...mapGetters('proposal', ['allProposals']),
    filteredProposals() {
      // Only return proposals that have is_finished = 0
      return this.allProposals.filter(proposal => proposal.is_finished === 0);
    },
    isAdmin() {
      return this.$store.getters['profile/me']?.role === 'global_admin';
    },
    isAgencyAdmin() {
      return this.$store.getters['profile/me']?.role === 'agency_admin';
    }
  },
  async mounted() {
    await this.loadProposals();
    this.initializeDataTable();

    eventBus.on('refreshComponent', this.refreshComponent);

    // Make methods globally available
    window.handleBoundClick = this.handleBoundClick.bind(this);
    window.handleLostClick = this.handleLostClick.bind(this);
    window.handleNewClick = this.handleNewClick.bind(this);
    window.continueProposal = this.continueProposal.bind(this);
  },
  beforeUnmount() {
    // Clean up the event listener
    eventBus.off('refreshComponent', this.refreshComponent);
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  },
  methods: {
    ...mapActions('proposal', ['fetchProposals']),

    openImportWizard() {
      this.showImportWizard = !this.showImportWizard;
    },

    handleCloseWizard() {
      this.showImportWizard = false; // Reset state when ImportWizard is closed
    },

    initializeDataTable() {
      this.$nextTick(() => {
        if (this.dataTable) {
          this.dataTable.destroy();
        }

        this.dataTable = new DataTable("#datatable-search", {
          searchable: true,
          fixedHeight: true
        });
      });
    },

    reinitializeDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
      }
      this.initializeDataTable();
    },

    async refreshComponent() {
      this.loading = true;
      await this.loadProposals();
      await this.initializeDataTable();
      this.tableKey += 1; // Change the key to force re-render
      this.loading = false;
    },

    async loadProposals() {
      console.log('loading proposals');
      await this.fetchProposals();
    },

    formatDate(date) {
      if (!date) return 'N/A';
      return format(parseISO(date), 'MMMM dd, yyyy');
    },

    formatCurrency(amount) {
      if (!amount) return '0.00';
      return parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + '...';
    },


    continueProposal(proposalId) {
      this.$router.push({
        name: "wasm",
        query: { id: proposalId }, // Pass 0 as the id in the query parameter
      });
    },
    /*
    continueProposal(proposalId) {
      const token = localStorage.getItem('access_token');
      if (token) {
        const customUrl = `ProtegoGap:?xt=${encodeURIComponent(token)}&dn=resumeSession&id=${encodeURIComponent(proposalId)}`;
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = customUrl;
        document.body.appendChild(iframe);

        let timeout = setTimeout(() => {
          document.body.removeChild(iframe);
          if (document.hasFocus()) {
            console.error("Custom protocol not handled...");
            if (confirm("The Protego Software needs to be installed to continue. Press 'OK' to download now.")) {
              axios.get(`${process.env.VUE_APP_API_BASE_URL}/download/installer/Windows`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob'
              })
              .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ProtegoInstaller.exe');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              })
              .catch(error => {
                console.error('Download failed', error);
                alert('Failed to download the installer. Please try again.');
              });
            }
          } else {
            console.log("Custom protocol handled by the browser.");
          }
        }, 1200);

        window.addEventListener('blur', () => {
          clearTimeout(timeout);
          window.removeEventListener('blur', this.handleBlur);
          if (document.body.contains(iframe)) {
            document.body.removeChild(iframe);
          }
          console.log("Protocol handled, focus lost.");
        });
      } else {
        console.error('No access token found in localStorage');
        alert('No access token found. Please log in.');
      }
    },
    */

    async handleCheckboxChange(proposal, type, isChecked) {
      if (type === 'bound') {
        proposal.is_bound = isChecked;
        if (isChecked) proposal.is_lost = false;
        document.getElementById('lostCheckbox-' + proposal.id).checked = false;
      } else if (type === 'lost') {
        proposal.is_lost = isChecked;
        if (isChecked) proposal.is_bound = false;
        document.getElementById('boundCheckbox-' + proposal.id).checked = false;
      } else if (type === 'new') {
        proposal.is_new = isChecked;
      }

      try {
        const token = localStorage.getItem('access_token');
        await axios.put(`${process.env.VUE_APP_API_BASE_URL}/agency/proposal/${proposal.id}`, {
          is_new: proposal.is_new,
          is_bound: proposal.is_bound,
          is_lost: proposal.is_lost,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (error) {
        console.error('Error updating proposal:', error);
      }
    },

    getProposalById(proposalId) {
      return this.allProposals.find(proposal => proposal.id === proposalId);
    },

    handleBoundClick(proposalId) {
      const proposal = this.getProposalById(proposalId);
      proposal.is_bound = !proposal.is_bound;
      if (proposal.is_bound) {
        proposal.is_lost = false;
      }
      this.handleCheckboxChange(proposal, 'bound', proposal.is_bound);
    },

    handleLostClick(proposalId) {
      const proposal = this.getProposalById(proposalId);
      proposal.is_lost = !proposal.is_lost;
      if (proposal.is_lost) {
        proposal.is_bound = false;
      }
      this.handleCheckboxChange(proposal, 'lost', proposal.is_lost);
    },

    handleNewClick(proposalId) {
      const proposal = this.getProposalById(proposalId);
      proposal.is_new = !proposal.is_new;
      this.handleCheckboxChange(proposal, 'new', proposal.is_new);
    },

    exportAll() {
      const token = localStorage.getItem('access_token');
      if (token) {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/download/csv`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `proposals_all.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error('Download failed', error);
          alert('Failed to download the CSV file. Please try again.');
        });
      } else {
        console.error('No access token found in localStorage');
        alert('No access token found. Please log in.');
      }
    },

    openNewProposal() {
      this.$router.push({
      name: "wasm"//,
      //query: { id: 0 }, // Pass 0 as the id in the query parameter
    });
    },

    openMagnetUrl() {
      const token = localStorage.getItem('access_token');
      if (token) {
        const customUrl = `ProtegoGap:?xt=${encodeURIComponent(token)}`;
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = customUrl;
        document.body.appendChild(iframe);

        let timeout = setTimeout(() => {
          document.body.removeChild(iframe);
          if (document.hasFocus()) {
            console.error("Custom protocol not handled...");
            if (confirm("The Protego Software is a cutting-edge cross-platform proposal management solution.\n\nIt is required to complete a proposal, press 'OK' to download now.")) {
              axios.get(`${process.env.VUE_APP_API_BASE_URL}/download/installer/Windows`, {
                headers: { 'Authorization': `Bearer ${token}` },
                responseType: 'blob'
              })
              .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ProtegoInstaller.exe');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              })
              .catch(error => console.error('Download failed', error));
            }
          } else {
            console.log("Custom protocol handled by the browser.");
          }
        }, 1200);

        window.addEventListener('blur', () => {
          clearTimeout(timeout);
          window.removeEventListener('blur', this.handleBlur);
          if (document.body.contains(iframe)) {
            document.body.removeChild(iframe);
          }
          console.log("Protocol handled, focus lost.");
        });
      } else {
        console.error('No access token found in localStorage');
        alert('No access token found. Please log in.');
      }
    }
  },
};
</script>


<style scoped>
.table-fixed {
    table-layout: fixed;
    width: 100%;
}

.table-fixed th, .table-fixed td {
    padding: 4px 8px;
    white-space: nowrap;
    overflow: visible;
    text-overflow: clip;
}

.checkbox-custom input[type="checkbox"] + span {
  background-color: transparent;
  border: 1px solid #ccc;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.checkbox-bound input[type="checkbox"]:checked + span {
  background-color: green;
}

.checkbox-lost input[type="checkbox"]:checked + span {
  background-color: red;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.styled-checkbox {
  appearance: none;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}
.styled-checkbox:checked {
  background-color: #4CAF50;
  border: 1px solid #fff;
}
.styled-checkbox:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
</style>
