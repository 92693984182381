<template>
  <nav id="navbarBlur" :key="key" :class="`${!isNavFixed
    ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
    : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${darkMode ? 'bg-default' : 'bg-white'
    } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />

      <!-- Back Button -->
      <div class="back-button">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="goBack">
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>
      <div class="refresh-button">
        <a href="#" class="p-0 nav-link text-body" @click.prevent="refreshPage">
          <i class="fas fa-sync-alt"></i>
        </a>
      </div>

      <div v-if="showSearchHeader" class="header-title" style="position: relative; margin-left: 100px; display: flex; align-items: center; flex-direction: column; gap: 10px;">
  <!-- Search Input -->
  <input 
    type="text" 
    placeholder="Search by name..." 
    class="form-control" 
    style="width: 150%; max-width: 600px; padding: 10px; border-radius: 5px; border: 1px solid #ccc; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);" 
    v-model="searchQuery"
    @input="filterResults"
  />

  <!-- Filtered Dropdown -->
  <ul 
    v-if="filteredResults.length > 0" 
    class="dropdown-menu show" 
    style="position: absolute; margin-left: -48px; top: calc(100% + 5px); left: 0; width: 150%; max-width: none; z-index: 1050; border-radius: 0.5rem; box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);"
  >
    <li 
      v-for="result in filteredResults" 
      :key="result.id" 
      class="dropdown-item border-radius-md" 
      style="cursor: pointer;" 
      @click="selectResult(result)"
    >
      {{ result.client_name }}
    </li>
  </ul>
</div>



      <div v-if="showHeaderTitle" class="header-title" style="display: flex; align-items: baseline;">
        <h3 style="margin: 20;">{{ headerTitle }}</h3>
        <h3 v-if="showingDaysToClose"
          :style="{ color: daysToClose < 7 ? 'red' : 'white', marginLeft: '28px', lineHeight: '1.2' }">
          {{ daysToClose }}
          <span style="color: white; font-size: .7em; margin-left: 3px;">Days to close</span>
        </h3>
      </div>




      <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'">
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
        </div>
        <ul class="navbar-nav justify-content-end">
          <li v-if="loggedIn" class="nav-item d-flex align-items-center">
            <a id="dropdownProfileMenu" href="#" style="display: contents;"
              :class="`nav-link ${showProfileDropdown ? 'show' : ''}`" data-bs-toggle="dropdown" aria-expanded="false"
              @click="showProfileDropdown = !showProfileDropdown">
              <span class="avatar avatar-sm rounded-circle">
                <img :src="profileImage" class="h-100 profile-image">
              </span>
              <span v-if="isRTL" class="d-sm-inline d-none text-white p-2">{{ getNameNavbar }}</span>
              <span v-else class="d-sm-inline d-none text-white p-2">{{ getNameNavbar }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end shadow position-absolute" style="margin-top: 0rem !important;"
              :class="showProfileDropdown ? 'show' : ''" aria-labelledby="dropdownProfileMenu">
              <div class="border-radius-md" style="padding: 0.3rem 1rem;">
                <small>
                  Welcome
                </small>
              </div>
              <router-link :to="{ name: 'User Profile' }">
                <li>
                  <a class="dropdown-item border-radius-md" href="javascript:;">
                    <div>
                      <i class="ni ni-circle-08"></i>
                      <span class="ms-2">My Profile</span>
                    </div>
                  </a>
                </li>
              </router-link>
              <hr class="m-2">
              <li>
                <a class="dropdown-item border-radius-md" href="#" @click.prevent="handleLogout">
                  <div>
                    <i class="ni ni-user-run"></i>
                    <span class="ms-2">Logout</span>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showSpinner" class="spinner-overlay">
      <div class="spinner"></div>
    </div>
  </nav>
</template>

<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState } from "vuex";
import placeholder from "../../assets/img/placeholder.jpg";
import showSwal from "../../mixins/showSwal.js";
import eventBus from "@/services/event-bus.service.js";
import axios from "axios";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs
  },
  mixins: [showSwal],
  data() {
    return {
      key: 0,
      showMenu: false,
      showProfileDropdown: false,
      showSpinner: false,
      profileImage: placeholder, // Default to placeholder initially
      headerTitle: "Default Title", // Initial default title
      showHeaderTitle: false, // Initially hidden
      showingDaysToClose: false, // Initially hidden
      showSearchHeader: false,
      daysToClose: 0,
      searchQuery: "",
      objects: [
        { name: "Alice", id: 1 },
        { name: "Bob", id: 2 },
        { name: "Charlie", id: 3 },
        { name: "Diana", id: 4 },
        { name: "Eve", id: 5 },
        { name: "Frank", id: 6 },
        { name: "Grace", id: 7 },
      ],
      filteredResults: [],
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    loggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    getNameNavbar() {
      return this.$store.getters["profile/me"]?.name;
    },
  },
  async created() {
    await this.fetchProfileImage();
    eventBus.on("updateHeaderTitle", this.updateHeaderTitle);
    eventBus.on("toggleHeaderVisibility", this.toggleHeaderVisibility);
    eventBus.on("toggleHeaderSearch", this.toggleHeaderSearch);
    eventBus.on("updateDaysToClose", this.updateDaysToClose);
    eventBus.on("toggleDaysToClose", this.toggleDaysToClose);
    eventBus.on("updateProposalsList", this.updateProposals);
  },
  beforeUnmount() {
    eventBus.off("updateHeaderTitle", this.updateHeaderTitle);
    eventBus.off("toggleHeaderVisibility", this.toggleHeaderVisibility);
    eventBus.off("toggleHeaderSearch", this.toggleHeaderSearch);
    eventBus.off("updateDaysToClose", this.updateDaysToClose);
    eventBus.off("toggleDaysToClose", this.toggleDaysToClose);
    eventBus.off("updateProposalsList", this.updateProposals);
  },

  methods: {
    filterResults() {
      console.log("FILTERING");
      // Filter based on the search query and limit results to 5
      if (this.searchQuery.trim().length > 0) {
        this.filteredResults = this.objects
          .filter((obj) => obj.client_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
          .slice(0, 5); // Limit to max 5 results
      } else {
        this.filteredResults = [];
      }
      console.log("Filtered Results : ",this.filteredResults);
    },
    selectResult(result) {
      // Handle selection from the dropdown
      console.log("Selected:", result);
      //this.searchQuery = result.client_name;
      this.searchQuery = '';
      this.filteredResults = []; // Clear dropdown after selection
      // Navigate to the /manage/:accountId route
      this.$router.push({ 
        path: `/manage/${result.id}` 
      });
    },
    updateHeaderTitle(newTitle) {
      this.headerTitle = newTitle;
    },

    updateProposals(propsoals) {
      this.objects = propsoals;
    },
    toggleHeaderSearch(show) {
      console.log("TOGGLING SEARCH HEADER VISIBILITY");
      this.showSearchHeader = show;
    },
    toggleHeaderVisibility(show) {
      console.log("TOGGLING HEADER VISIBILITY");
      this.showHeaderTitle = show;
      //this.showSearchHeader = show;

      
    },
    toggleDaysToClose(show) {
      console.log("TOGGLING DAYS TO CLOSE");
      this.showingDaysToClose = show;
    },
    updateDaysToClose(days) {
      this.daysToClose = days;
    },
    async fetchProfileImage() {
      try {
        const token = localStorage.getItem("access_token");

        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/profile/image`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // Only set the profile image if the response contains a valid blob.
        if (response.data.size > 0) {
          const imageUrl = URL.createObjectURL(response.data);
          this.profileImage = imageUrl;
        } else {
          this.profileImage = placeholder; // Fallback to placeholder if no valid image
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
        this.profileImage = placeholder; // Fallback to placeholder in case of an error
      }
    },
    async handleLogout() {
      try {
        // Retrieve the access token
        const token = localStorage.getItem("access_token");

        if (token) {
          // Delete the access token from localStorage
          localStorage.removeItem("access_token");
          console.log("Access token deleted.");
        } else {
          console.warn("No access token found.");
        }
      } finally {
        // Navigate to the Login page
        this.$store.commit('agencyStyle/CLEAR_AGENCY_STYLE');
        await this.$router.push({ name: "Login" });
      }
    },
    goBack() {
      // Go back to the previous page
      window.history.back();
    },
    refreshPage() {
      this.showSpinner = true;
      eventBus.emit('refreshComponent');
      setTimeout(() => {
        this.showSpinner = false;
        window.location.reload();
      }, 300);
    },
  },
};
</script>

<style scoped>
.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}

.back-button .nav-link {
  font-size: 18px;
  color: white;
  padding: 0;
}

.back-button .nav-link i {
  color: white;
}

.refresh-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}

.refresh-button .nav-link {
  font-size: 18px;
  color: white;
  padding: 0;
}

.refresh-button .nav-link i {
  color: white;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.header-title {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.header-title h3 {
  margin: 0;
  font-size: 24px;
  color: white;
}

/* Optional styles for dropdown interactivity */
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
</style>
